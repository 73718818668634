import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import noCarImage from '../../assets/images/no_car_img.png';
import { CarPill } from '../car-card/components';
import Button from '../common/button/Button.component';
import ColorBox from '../common/ColorBox.component';
import { useQuery } from '@apollo/react-hooks';
import { useAppContext } from '../../context/AppContext';
import { GET_RESERVATION_BY_USER_ID } from '../../queries';
import moment from 'moment';
import { window } from 'global';
import { Link } from 'gatsby';
import Loading from '../common/Loading.component';
import { navigate } from 'gatsby';
import { formatPrice } from '../../utils/currency.utils';
import { GET_BUDGET_GENERATED_BY_USER_ID } from '../../queries/getBudgetGeneratedByUser';

const useStyles = makeStyles((theme) => ({
  carContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: '1px solid #D1D1D1',
    borderRadius: '10px',
    width: '942px',
    height: '234px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      height: 'auto',
      width: '85%',
    },
  },
  imageContainer: {
    width: '311px',
    marginLeft: '16px',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '22px',
    [theme.breakpoints.down('md')]: {
      margin: '0 !important',
      height: 'auto',
      width: '100%',
      paddingTop: '16px',
    },
  },
  inf: {
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginLeft: '16px',
    gap: '16px',
    [theme.breakpoints.down('md')]: {
      width: '290px',
      height: 'auto',
      marginBottom: '16px',
      marginLeft: '0',
      overflow: 'hidden',
    },
  },
  pdf: {
    width: '200px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    borderLeft: '1px solid #D1D1D1',
    paddingTop: '3rem',
    paddingBottom: '3rem',
    [theme.breakpoints.down('md')]: {
      borderTop: '1px solid #D1D1D1',
      borderLeft: 'unset',
      width: '100%',
      paddingTop: '0rem',
      height: '80px',
      paddingTop: '1rem',
      margin: '2rem'
    }
  },
  image: {
    width: '305px',
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      height: 'auto',
    }
  },
  dateTitle: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    color: '#262626',
  },
  date: {
    fontSize: '18px',
    letterSpacing: '0.008em',
    marginLeft: '10px',
  },
  carBrandModel: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.02em',
    color: '#5C5C5C',
  },
  carVersion: {
    color: '#5C5C5C',
    marginLeft: '8px',
  },
  colorBox: {
    [theme.breakpoints.down('md')]: {
      height: '30px',
    }
  },
  noListBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '30px',
    marginBottom: '30px',
    marginTop: '100px',
  },
  noListText: {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#5C5C5C',
  },
  buttons:{
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '1.5rem'
  },
  buttonsPayment:{  
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
    }
  }
}));

const BudgetGeneratedData = ({ }) => {
  const classes = useStyles();
  const [budgetGeneratedList, setbudgetGeneratedList] = React.useState([]);
  const { user: { profile: { id: userId } } } = useAppContext();


      const { data: dataGenerado, refetch : refetchGenerado, loading: loadingGenerado } = useQuery(GET_BUDGET_GENERATED_BY_USER_ID, {
        variables: {
          id: userId
        },
        fetchPolicy: 'network-only',
        onCompleted: (success) => {
          success && refetchGenerado();
        }
      });
    
      React.useEffect(() => {
        dataGenerado && setbudgetGeneratedList(dataGenerado.budgetGeneratedByUserId || []);
      }, [dataGenerado]);


  const filteredList =  budgetGeneratedList

  return filteredList.length === 0
    ? loadingGenerado ? <Loading size={20} /> : <Box className={classes.noListBox} >
      
      <div className={classes.noListText} >
        <div>Todavía no presupuestate tu auto.</div>   
        <div>¡Encontrá el tuyo!</div>
      </div>
      <Link to='/catalogo/' >
        <Button type='secondary' >Buscar mi auto</Button>
      </Link>
    </Box>
    : filteredList.map((car) =>
      <Box key={car.date} className={classes.carContainer} >
        <Box className={classes.imageContainer} >
          <img src={car?.image} className={classes.image} alt='imagen' onError={e => {
            e.target.onerror = null;
            e.target.src = noCarImage;

          }} />
        </Box>
        <Box className={classes.inf} >
            <div className={classes.dateTitle}>
              Fecha: <span className={classes.date}>{moment(car.date).format('DD/MM/YYYY')}</span>
            </div>
          <div>
            <span className={classes.carBrandModel} >{car.brand} {car.model}</span>
            <span className={classes.carVersion}>{car.version}</span>
          </div>
          <CarPill car={{
            is0km: car.is0km,
            km: car.km,
            year: car.year,
            fuel: car.fuel,
          }} />
          <Box display="flex">
            <ColorBox color={car.color?.name} hex={car.color?.hex} isCatalogCarmuv/>
          </Box>
        </Box>
        <Box className={classes.pdf} >
          <Box className={classes.buttons}>
            <Button type='link' onClick={e => {
              e.preventDefault();
              window.open(car.pdf, '_blank');
            }} >Ver presupuesto</Button>
          </Box>
        </Box>
      </Box>
    );
};

export default BudgetGeneratedData;
